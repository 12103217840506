import AjaxForm from '../../organisms/form/AjaxForm';
import type { iStepValidationStrategy } from './validation-strategies/iStepValidationStrategy';
import stepValidationStrategyTypes from './validation-strategies/stepValidationStrategyTypes';
import DefaultStepValidationStrategy from './validation-strategies/DefaultStepValidationStrategy';
import { FormFieldEvents } from '../../molecules/form-field/FormField';

export type StepProps = {
    step: number;
    strategy: iStepValidationStrategy;
    continueText: string;
    stepTitle?: string;
    flow: string;
    previous: number;
    content: string;
};

export enum StepEvents {
    SHOWN = 'STEP_SHOWN',
    HIDDEN = 'STEP_HIDDEN'
}

export default class Step extends HTMLElement {
    #isValid = true;

    #form: HTMLFormElement | AjaxForm | null = this.querySelector('form');

    #stepTitle = this.getAttribute('step-title') || '';

    get isValid(): boolean {
        return this.#isValid;
    }

    set isValid(value: boolean) {
        this.#isValid = value;
    }

    get stepTitle(): string {
        return this.#stepTitle;
    }

    set stepTitle(value: string) {
        this.#stepTitle = value;
    }

    get continueText(): string {
        return this.getAttribute('continue-text') || '';
    }

    get step(): number {
        return Number(this.getAttribute('step'));
    }

    set step(value: number) {
        if (value === null) return;
        this.setAttribute('step', value.toString());
    }

    get flow(): string | null {
        return this.hasAttribute('flow') ? this.getAttribute('flow') : null;
    }

    set flow(value: string | null) {
        if (value === null) return;
        this.setAttribute('flow', value);
    }

    get previous(): number | null {
        return this.hasAttribute('previous') ? Number(this.getAttribute('previous')) : null;
    }

    set previous(value: number | null) {
        if (value === null) return;
        this.setAttribute('previous', value.toString());
    }

    get strategy(): iStepValidationStrategy {
        const strategyAttribute = this.getAttribute('strategy') || '';

        const Strategy = stepValidationStrategyTypes[strategyAttribute];

        if (!Strategy) {
            return new DefaultStepValidationStrategy(this);
        }

        return new Strategy(this);
    }

    get hidden(): boolean {
        return this.hasAttribute('hidden');
    }

    set hidden(value: boolean) {
        this.toggleAttribute('hidden', value);

        this.dispatchEvent(new Event(value ? StepEvents.HIDDEN : StepEvents.SHOWN));
    }

    public validate(reportValidity = false): boolean {
        this.isValid = this.strategy.validate(reportValidity);
        this.dispatchEvent(
            new Event('validate', {
                bubbles: true
            })
        );

        return this.isValid;
    }

    public async submit(): Promise<boolean> {
        if (this.#form instanceof AjaxForm) {
            return Promise.resolve(await this.#form.submitForm());
        }

        return Promise.resolve(true);
    }

    constructor() {
        super();

        this.step = this.#calculateStep();
        this.isValid = this.validate(false);
    }

    protected connectedCallback(): void {
        this.#form?.addEventListener('change', this.validate.bind(this, false));
        this.#form?.addEventListener('input', this.validate.bind(this, false));

        this.addEventListener(FormFieldEvents.IS_VALID, () => {
            this.validate();
        });
    }

    protected disconnectedCallback(): void {
        this.#form?.removeEventListener('change', this.validate.bind(this, false));
        this.#form?.removeEventListener('input', this.validate.bind(this, false));
    }

    #calculateStep(): number {
        const parentElement = this.parentElement as HTMLElement;

        if (this.hasAttribute('step')) {
            const step = this.getAttribute('step');
            const isUnique = parentElement.querySelectorAll(`[step="${step}"]`).length === 1;
            if (!isUnique) {
                throw new Error('Step attribute must be unique!');
            }
            return Number(step);
        }

        const index = Array.prototype.indexOf.call(parentElement.children, this) + 1;

        if (index <= 0) {
            throw new Error('misconfigured step');
        }

        return index;
    }
}

if (!window.customElements.get('nh-step')) {
    window.customElements.define('nh-step', Step);
}
